<template>
  <v-footer padless rounded class="justify-center">
    <v-card rounded width="100%" class="lighten-1 text-center">
      <v-card-text>
        {{ new Date().getFullYear() }} &copy; <strong>SOSCONF.zh 2020</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {};
</script>

<style></style>
